import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
    standalone: false
})
export class PreviewComponent implements OnInit {

  @Input() public imagesObj;
  imgObj;
  imgObjIndex;
  constructor(public activeModal: NgbActiveModal ) { }

  ngOnInit(): void {
    this.imgObjIndex = this.imagesObj.index;
    this.imgObj = this.imagesObj.arrAlbumImages[this.imagesObj.index];
  }

  nextPreImage(value) {
    if (value == 1) {
        this.imgObjIndex = this.imgObjIndex - 1;
        this.imgObj = this.imagesObj.arrAlbumImages[this.imgObjIndex];
    } else {
        this.imgObjIndex = this.imgObjIndex + 1;
        this.imgObj = this.imagesObj.arrAlbumImages[this.imgObjIndex];
    }
}

  closeModal() {
    this.activeModal.dismiss('Modal Closed');
  }

}
