<div class="modal-content">
    <div class="modal-header">
        <a class="closeModal" (click)="closeModal()" data-bs-dismiss="modal"> <img src="assets/images/close.png">
        </a>
    </div>
    <div class="modal-body">
        <div class="preview-img">
            <i class="left" *ngIf="imgObjIndex != 0" (click)="nextPreImage(1)">
                <img src="assets/images/carrocel-arrow.png" />
            </i>
            <figure>
                <img src="{{imgObj.fullSrc}}"/>
            </figure>
            <i class="right"  *ngIf="imgObjIndex < imagesObj.arrAlbumImages.length-1" (click)="nextPreImage(2)">
                <img src="assets/images/carrocel-arrow.png" />
            </i>
        </div>
    </div>
</div>
